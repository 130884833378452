/**
 * External dependencies
 */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

/**
 * Component
 */
const Image = ( { name, alt } ) => (
    <StaticQuery
        query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(
                maxWidth: 800,
              ) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
        render={( data ) => {
            const image = data.allImageSharp.edges.find(
                edge => edge.node.fluid.originalName === name,
            );
            if ( ! image ) {
                return null;
            }
            return <Img fluid={image.node.fluid} alt={alt} />;
        }}
    />
);

Image.propTypes = {
    name: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default Image;
