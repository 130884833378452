/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import GrayscaleImage from '../grayscale-image';
import Image from '../image';
import SquareImage from '../square-image';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const ShapedImage = ( {
    name, alt, grayscale, withBg, flipped, pullLeft, pullRight, square, caption, small, style,
} ) => {
    const classes = classNames(
        'shaped-image', {
            'with-bg': withBg,
            'is-flipped': flipped,
            'pull-left': pullLeft,
            'pull-right': pullRight,
            'is-small': small,
        },
    );

    if ( square ) {
        return (
            <div className={classes} style={ style }>
                <div className="shaped-image-inner">
                    <SquareImage name={name} alt={alt} />
                </div>
            </div>
        );
    }

    return (
        <figure className={classes} style={ style }>
            <div className="shaped-image-inner">
                { grayscale ? <GrayscaleImage name={name} alt={alt} />
                    : <Image name={name} alt={alt} /> }
            </div>
            { caption && <figcaption>{ caption } </figcaption> }
        </figure>
    );
};

ShapedImage.propTypes = {
    grayscale: PropTypes.bool,
    name: PropTypes.string.isRequired,
    withBg: PropTypes.bool,
    flipped: PropTypes.bool,
    alt: PropTypes.string.isRequired,
    pullLeft: PropTypes.bool,
    pullRight: PropTypes.bool,
    square: PropTypes.bool,
};

ShapedImage.defaultProps = {
    grayscale: false,
    withBg: false,
    flipped: false,
    pullLeft: false,
    pullRight: false,
    square: false,
};

export default ShapedImage;
